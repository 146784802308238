import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseGiftMessageMutation, UseGiftMessageErrors, UseGiftMessageInterface, UseGiftMessageUpdateGiftMessageParams } from './useGiftMessage';
import type { Cart } from '~/modules/GraphQL/types';
import { useApi } from '~/composables/useApi';

const SET_GIFT_OPTIONS_ON_CART = `
mutation setGiftOptionsOnCart($cartId: String!, $gift_message: GiftMessageInput!) {
  setGiftOptionsOnCart(
    input: {
      cart_id: $cartId,
      gift_message: $gift_message,
      gift_receipt_included: false,
      printed_card_included: false
    }
  ) {
    cart {
      id,
      gift_message {
        from
        to
        message
      }
    }
  }
}
`;

export function useGiftMessage(): UseGiftMessageInterface {
  const loading = ref(false);
  const error = ref<UseGiftMessageErrors>({
    updateGiftMessage: null
  });
  const { mutate } = useApi();

  const updateGiftMessage = async (cartId: string, params: UseGiftMessageUpdateGiftMessageParams): Promise<Cart | null> => {
    Logger.debug('useGiftMessage/updateGiftMessage', { params });
    let result = null;

    try {
      loading.value = true;
      error.value.updateGiftMessage = null;
      const { from, to, message } = params;
      const { data } = await mutate<UseGiftMessageMutation>(SET_GIFT_OPTIONS_ON_CART, {
        cartId,
        gift_message: {
          from,
          to,
          message
        }
      });

      result = data.setGiftOptionsOnCart.cart ?? null;
    } catch (err) {
      error.value.updateGiftMessage = err;
      Logger.error('useGiftMessage/updateGiftMessage', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    updateGiftMessage,
    error: readonly(error),
    loading: readonly(loading)
  };
}
