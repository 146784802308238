import { useNostoApi } from './useNostoApi';

/**
 * Sends search page viewed action to the Nosto.
 * @param query - The search query string to be sent to the Nosto.
 */
export function useNostoSearch(query: string) {
  if (!query) return;

  useNostoApi(async (api) => {
    // eslint-disable-next-line prettier/prettier
    return api
              .defaultSession()
              .viewSearch(query)
              .setPlacements(api.placements.getPlacements())
              .load();
  }, 'SEARCH');
}
