<script>
export default {
  name: 'VaimoSpyWrapper',
  functional: true,
  props: ['tag'],
  render(h, context) {
    const { children, props, data } = context;
    if (props.tag) {
      return h(props.tag, data, children);
    }
    return children;
  }
};
</script>
