import { defineStore } from 'pinia';

interface useMegaMenuMobileStoreInterface {
  open: boolean;
  isMenuLayersVisible: boolean;
  isDesktopMenuOpen: boolean;
}

export const useMegaMenuMobileStore = defineStore('megaMenuMobile', {
  state: (): useMegaMenuMobileStoreInterface => ({
    open: false,
    isMenuLayersVisible: false,
    isDesktopMenuOpen: false
  }),

  actions: {
    hide(): void {
      this.open = false;
    },
    show(): void {
      this.open = true;
    }
  }
});
