import { useContext } from '@nuxtjs/composition-api';

import { Product } from '~/modules/catalog/product/types';
import { CategoryTree } from '~/modules/GraphQL/types';

export function useCanonical() {
  const {
    app: { i18n }
  } = useContext();

  const canonicalProductLink = (product: Product) => {
    if (!product || !product?.url_key || !product?.url_suffix || !i18n.localeProperties?.code) {
      return {};
    }
    return {
      rel: 'canonical',
      href: new URL(`${i18n.localeProperties.code}/p/${product.url_key}${product.url_suffix}`, process.env.VSF_MAGENTO_BASE_URL).href
    };
  };

  const canonicalCategoryLink = (category: CategoryTree) => {
    if (!category || !category?.url_path || !category?.url_suffix || !i18n.localeProperties?.code) {
      return {};
    }
    return {
      rel: 'canonical',
      href: new URL(`${i18n.localeProperties.code}/l/${category.url_path}${category.url_suffix}`, process.env.VSF_MAGENTO_BASE_URL).href
    };
  };

  return {
    canonicalProductLink,
    canonicalCategoryLink
  };
}
