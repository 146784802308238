export const emailRegex = /^(([^¡-■<>()[\]\\.,;:\s@"]+(\.[^¡-■<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const nameRegex = /^[a-zA-ZÀ-Ÿ\s., &#/!'-]+$/u;
export const latinRegex = /^[a-zA-ZÀ-Ÿ0-9\s., &#/!'-]+$/u;
export const phoneRegex = /^(?:\+)?[\s\d]{0,21}$/;
export const chineseRegex = /[\p{Script=Hani}]/gu;
export const urlRegExp = new RegExp('^(?:[a-z+]+:)?//', 'i');
export const domainOnlyRegex = /(?:https?:\/\/)?(?:www\.)?(?:[^\/:]+\.)?([^\/:]+\.[a-zA-Z]{2,})(?=\/|$)/;
export const charsRegex = /^[^;]*$/;
export const doubleQuotesRegex = /^[^"]*$/;
export const katakanaRegex = /^([\u30A1-\u30FC])*$/;
export const hiraganaRegex = /^([\u3040-\u309F|\u30FB-\u30FC])*$/;
export const kanaRegex = /^([\u3040-\u309F|\u30FB-\u30FC|\u30A1-\u30FC])*$/;
export const phoneRegexJP = /^[0-9]{10,11}$/;
export const postcodeJP = /^([0-9]{7}|[0-9]{3}\-?[0-9]{4})$/;
