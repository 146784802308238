export default `
  query getProductUrlBySku($filter: ProductAttributeFilterInput, $is_preview: Boolean = false) {
    products(filter: $filter, is_preview: $is_preview) {
      items {
        url_key
        url_rewrites {
          url
        }
      }
    }
  }
`;
