import { computed, watch } from '@nuxtjs/composition-api';
import { useMediaQuery } from '@vueuse/core';

import { useScreenSizeStore } from '~/diptyqueTheme/stores/screenSize.js';

export function useScreenSize() {
  const store = useScreenSizeStore();

  if (store.isDesktop !== null) {
    return {
      screenSize: store.screenSize,
      isDesktop: store.isDesktop
    };
  }

  const max1023 = useMediaQuery('(max-width: 1023px)');
  const min1024 = useMediaQuery('(min-width: 1024px)');
  const max1359 = useMediaQuery('(max-width: 1359px)');
  const min1360 = useMediaQuery('(min-width: 1360px)');
  const max1919 = useMediaQuery('(max-width: 1919px)');
  const min1920 = useMediaQuery('(min-width: 1920px)');

  const screenS = computed(() => max1023.value);
  const screenM = computed(() => min1024.value && max1359.value);
  const screenL = computed(() => min1360.value && max1919.value);
  const screenXL = computed(() => min1920.value);

  const screenSize = computed(() => {
    if (screenS.value) return 's';
    if (screenM.value) return 'm';
    if (screenL.value) return 'l';
    if (screenXL.value) return 'xl';
    return null;
  });

  const isDesktop = computed(() => {
    return screenSize.value === null ? null : screenSize.value !== 's';
  });

  const isMobile = computed(() => !isDesktop.value);

  watch(screenSize, () => {
    store.screenSize = screenSize;
    store.isDesktop = isDesktop;
  });

  return {
    screenSize,
    isDesktop,
    isMobile
  };
}
