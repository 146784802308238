/** GraphQL query to get the cart of the current logged in customer */
export default `
  query customerCartStockStatus {
    customerCart {
      id
      items {
        id
        product {
          id
          stock_status
        }
      }
    }
  }
`;
