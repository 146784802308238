export default `
  query getProductUrlPath($url: String!, $is_preview: Boolean = false)
  {
    route(url: $url, is_preview: $is_preview) {
      __typename
      relative_url
      redirect_code
      type
      ... on ProductInterface {
        show_estimation_delivery_date
        enabled
        id
        uid
        sku
        preorder
        replenishable
        preorderButtonLabel
        preorderStockNotice
        name
        stock_status
        is_salable
        country_of_manufacture
        identity_name
        detailed_description
        subtitle
        custom_message
        plp_description
        pdp_short_description
        pdp_long_description
        ... on CustomizableProductInterface {
          options {
            title
            uid
          }
        }
        editorial {
          label
          value
        }
        reinsurance {
          label
          value
        }
        formule {
          label
          value
        }
        deco_know_how {
          label
          value
        }
        usage_tips {
          label
          value
        }
        ingredients {
          label
          value
        }
        deco_material_type {
          label
          value
        }
        price_excluded_tax
        only_x_left_in_stock
        subtitle
        variant_name {
          label
          value
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        categories {
          uid
          name
          url_suffix
          url_path
        }
        rating_summary
        review_count
        reviews {
          items {
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        small_image {
          url
          position
          disabled
          label
        }
        image {
          url
          position
          disabled
          label
        }
        media_gallery {
          url
          position
          disabled
          label
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        url_suffix
        meta_description
        meta_keyword
        meta_title
        description {
          label
          html
        }
        short_description {
          html
        }
        hover_on_card
        edito_image
        capacity {
          label
          value
        }
        revamp_related_products {
          sku
          plp_description
          variant_name {
            label
          }
          capacity {
            label
            value
          }
          variant_name {
            label
            value
          }
          position
          thumbnail {
            url
            label
          }
          stock_status
          is_salable
        }
        upsell_products {
          sku
          position
        }
        crosssell_products {
          id
          uid
          sku
          stock_status
          is_salable
          price_type
          custom_set_minimal_price
          custom_set_minimal_price_excluded_tax
          number_of_sets
          plp_description
          position
          variant_name {
            label
          }
          __typename
          revamp_related_products {
            sku
            capacity {
              label
              value
            }
            variant_name {
              label
              value
            }
            position
            thumbnail {
              url
              label
            }
            stock_status
          }
          thumbnail {
            url
            position
            disabled
            label
          }
          short_description {
            html
          }
          url_rewrites {
            url
          }
          categories {
            uid
            name
            url_suffix
            url_path
          }
          url_key
          name
          special_price
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              final_price {
                value
                currency
              }
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        options_container
        special_to_date
      }
    }
  }
`;
