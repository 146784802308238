<template>
  <VaimoSpyWrapper
    v-if="iconExist"
    :tag="tag"
    class="vaimo-icon"
    :data-icon="iconName"
    :aria-label="label"
    :role="role || (label ? 'img' : null)"
    :style="wrapperStyle"
  >
    <span class="vaimo-icon__inner" :style="innerStyle" />
  </VaimoSpyWrapper>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoSpyWrapper from 'atoms/helper/VaimoSpyWrapper.vue';

import spritesheet from '~/diptyqueTheme/assets/icons/sprite/spritesheet.json';

export default defineComponent({
  name: 'VaimoIcon',
  components: { VaimoSpyWrapper },
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 24
    },
    tag: {
      type: String,
      default: 'i'
    },
    label: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const iconName = computed(() => props.icon);
    const icon = computed(() => spritesheet[iconName.value]);
    if (!icon.value) {
      console.info(`Icon not found: ${iconName.value}`);
      return {
        iconExist: false
      };
    }

    const scale = computed(() => props.size / icon.value.width);
    const width = computed(() => icon.value.width * scale.value);
    const height = computed(() => icon.value.height * scale.value);

    const wrapperStyle = computed(() => {
      return {
        minWidth: `${width.value}px`,
        width: `${width.value}px`,
        height: `${height.value}px`
      };
    });

    const innerStyle = computed(() => {
      return {
        width: `${icon.value.width}px`,
        height: `${icon.value.height}px`,
        backgroundPosition: `-${icon.value.x}px -${icon.value.y}px`,
        transform: `scale(${scale.value})`
      };
    });

    return {
      iconExist: true,
      iconName,
      wrapperStyle,
      innerStyle
    };
  }
});
</script>

<style lang="scss" scoped>
.vaimo-icon {
  display: inline-block;
  overflow: hidden;
  outline-color: $noir;

  &__inner {
    display: inline-block;
    background-image: url('~/diptyqueTheme/assets/icons/sprite/icons.webp');
    background-repeat: no-repeat;
    transform-origin: top left;
  }
}
</style>
