export default `
  query getProductUrlPath($url: String!, $is_preview: Boolean = false)
  {
    route(url: $url, is_preview: $is_preview) {
      __typename
      type
      ... on ProductInterface {
        name
        breadcrumbs {
          title
          link
        }
      }
    }
  }
`;
