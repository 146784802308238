import { defineStore } from 'pinia';
import type { NuxtError } from '@nuxt/types';

interface UseErrorLayoutStatusStoreInterface {
  statusCode?: number;
}

export const useErrorLayoutStatusStore = defineStore('errorLayoutStatus', {
  state: (): UseErrorLayoutStatusStoreInterface => ({
    statusCode: null
  }),

  actions: {
    setErrorStatusCode(error: NuxtError): void {
      this.statusCode = error.statusCode;
    },
    getErrorStatusCode(): number {
      return this.statusCode;
    }
  }
});
