




import { defineComponent } from '@nuxtjs/composition-api';

import { useNostoNotFound } from '~/diptyqueTheme/composable/nosto/useNostoNotFound';

export default defineComponent({
  name: 'NostoNotFound',
  setup() {
    useNostoNotFound();
  }
});
