import { useNostoApi } from './useNostoApi';

/**
 * Sends 404 page viewed action to the Nosto.
 */
export function useNostoNotFound() {
  useNostoApi((api) => {
    // eslint-disable-next-line prettier/prettier
    return api.defaultSession()
              .viewNotFound()
              .setPlacements(api.placements.getPlacements())
              .load();
  }, 'NOT_FOUND');
}
