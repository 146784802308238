













import { defineComponent, computed, watch, onBeforeUnmount } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import Page404 from '~/diptyqueTheme/pages/Page404.vue';
import { useErrorLayoutStatusStore } from '~/diptyqueTheme/stores/error';

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    VaimoHeading: () => import('~/components/atoms/VaimoHeading.vue'),
    VaimoButton: () => import('~/components/atoms/VaimoButton.vue'),
    Page404
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true
    }
  },
  setup(props) {
    const error = computed(() => props.error);
    const errorLayoutStatusStore = useErrorLayoutStatusStore();

    watch(
      error,
      () => {
        errorLayoutStatusStore.setErrorStatusCode(error.value);
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      //reset error status code
      errorLayoutStatusStore.setErrorStatusCode({
        statusCode: null
      } as NuxtError);
    });
  }
});
