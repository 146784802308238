export default `
query countryInformationGlobal($id: String) {
    globalScopeCountry(id: $id) {
        id
        two_letter_abbreviation
        full_name_locale
        full_name_english
        available_regions {
            id
            code
            name
        }
    }
}
`;
