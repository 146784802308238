import {
  computed, readonly, ref, useContext,
} from '@nuxtjs/composition-api';
import type {
  LRUCache,
  useLruCacheType
} from '@vaimo/vsf2_ext-lru-cache-driver';
import { useLruCache } from '@vaimo/vsf2_ext-lru-cache-driver';
import { useVSFContext } from '@vue-storefront/core';
import { Logger } from '~/helpers/logger';
import { useConfigStore } from '~/stores/config';
import type { UseConfigErrors, UseConfigInterface, UseConfigLoadParams } from './useConfig';

export function useConfigCache({ locale, $magento }: { locale: string; $magento: any }) {
  const lruCacheContainers = <useLruCacheType>useLruCache();
  // max age in ms, set 5min
  // eslint-disable-next-line no-magic-numbers
  const ttl = 1000 * 60 * 5;
  const containerName = 'general';
  const cacheKey = 'config-' + locale;

  const execute = () => {
    /**
     * Handle cached result.
     * @param cachedResult {Object}
     */
    const onCachedResult = (cachedResult) => {
      return lruCacheContainers.unproxify(cachedResult.storeConfigData);
    };

    /**
     * When booleanCheckOnResult returned false then execute raw non-cached call and save to SSR side cache for 5min.
     * @param cache {LRUCache}
     */
    const onNonCachedResult = async (cache: LRUCache) => {
      const { data } = await $magento.api.customStoreConfig();

      cache.set(
        cacheKey,
        {
          storeConfigData: data || {},
        },
        ttl
      );

      return data || {};
    };

    /**
     * Check for cached result, if contains required variables to use, if false, then onNonCachedResult() will be called.
     * @param cachedResult
     */
    const booleanCheckOnResult = (cachedResult) => cachedResult && cachedResult.storeConfigData;

    return lruCacheContainers.execute(
      containerName,
      cacheKey,
      onCachedResult,
      onNonCachedResult,
      booleanCheckOnResult
    );
  };

  return { execute };
}

/**
 * Allows interacting with the store configuration.
 *
 * See the {@link UseConfigInterface} for a list of methods and values available in this composable.
 */
export function useConfig(): UseConfigInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseConfigErrors>({ load: null });
  const configStore = useConfigStore();
  const {
    app: {
      i18n: { locale }
    }
  } = useVSFContext();

  const cache = useConfigCache({ locale, $magento: app.$vsf.$magento });

  const load = async () => {
    error.value.load = null;
    loading.value = true;

    Logger.debug('useConfig/load');

    try {
      const data = await cache.execute();

      configStore.$patch((state) => {
        state.storeConfig = data.storeConfig || {};
      });
    } catch (err) {
      Logger.debug('[ERROR] useConfig/load', err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    config: computed(() => configStore.storeConfig),
    loading: readonly(loading),
    load
  };
}

export * from './useConfig';
export default useConfig;
