export const LOGGED_IN = 'logged_in';
export const LOGGED_OUT = 'not_logged_in';
export const NO_ERROR = 'na';
export const ECOMMERCE_EVENT = 'ecommerce';
export const EVENT_SELECT_ITEM = 'select_item';
export const EVENT_VIEW_ITEM = 'view_item';
export const EVENT_VIEW_ITEM_LIST = 'view_item_list';
export const EVENT_ADD_TO_CART = 'add_to_cart';
export const EVENT_REMOVE_FROM_CART = 'remove_from_cart';
export const EVENT_SHOW_CARTPAGE = 'show_cartpage';
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const EVENT_LOGIN = 'login';
export const EVENT_OPEN_LOGIN = 'openLoginLayer';
export const EVENT_ADD_DELIVERY_ADDRESS = 'add_address_info';
export const EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
export const EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
export const EVENT_PURCHASE = 'purchase';
export const EVENT_RESULT_SEARCH = 'resultSearch';
export const EVENT_RESULT_AUTOCOMPLETE = 'resultAutocomplete';
