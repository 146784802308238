import { ref } from '@nuxtjs/composition-api';

interface Callbacks {
  onDisablesScroll?: () => void;
  onDisablesScrollUp?: () => void;
  onDisablesScrollDown?: () => void;
}

let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true;
      }
    })
  );
} catch (e) {}

export function useDisabledScroll(callbacks: Callbacks) {
  // left: 37, up: 38, right: 39, down: 40, space bar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  const keys = { 32: 1, 33: 1, 34: 1, 35: 1, 36: 1, 37: 1, 38: 1, 39: 1, 40: 1 };
  const debounceTime = 1000;
  let lastFireTime = 0;

  const fireCallbacks = (e): void => {
    const currentTime = Date.now();
    if (currentTime - lastFireTime > debounceTime) {
      if (callbacks.onDisablesScroll) callbacks.onDisablesScroll();
      if (callbacks.onDisablesScrollUp && e.deltaY < 0) callbacks.onDisablesScrollUp();
      if (callbacks.onDisablesScrollDown && e.deltaY > 0) callbacks.onDisablesScrollDown();
      lastFireTime = currentTime;
    }
  };

  const preventDefault = (e): void => {
    fireCallbacks(e);
    e.preventDefault();
  };

  const preventDefaultForScrollKeys = (e): boolean => {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
    return true;
  };

  const wheelOpt = supportsPassive ? { passive: false } : false;
  const wheelEvent = process.client && 'onwheel' in window.document.createElement('div') ? 'wheel' : 'mousewheel';

  const isScrollDisabled = ref(false);

  const disableScroll = () => {
    isScrollDisabled.value = true;
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  };

  const enableScroll = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);

    isScrollDisabled.value = false;
  };

  return {
    isScrollDisabled,
    disableScroll,
    enableScroll
  };
}
