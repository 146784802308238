/** GraphQL Mutation that sets a password for users logged in with socials. */

export default `
  mutation setSocialLoginPassword($userToken: String!, $userEmail: String!, $newPassword: String!) {
     setSocialLoginPassword(
      userToken: $userToken,
      userEmail: $userEmail,
      newPassword: $newPassword
    )
  }
`;
