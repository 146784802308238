
export default function () {
    if (process.env.GOOGLE_TAG_MANAGER_ID_EXTRA) {
        // Add additional container script tag to head
        let script = document.createElement("script")
        script.async = true
        script.src = "https://www.googletagmanager.com/gtm.js?id=" + process.env.GOOGLE_TAG_MANAGER_ID_EXTRA
        document.head.appendChild(script)

        // add gtm initialiser
        let scriptWindow = document.createElement("script")
        scriptWindow.async = true
        scriptWindow.innerHTML = "if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('" + process.env.GOOGLE_TAG_MANAGER_ID_EXTRA + "')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}";
        document.head.appendChild(scriptWindow)

        // Add additional container <noscrip> tag to body
        let noscript = document.createElement("div")
        noscript.dataset.nHead = "ssr"
        noscript.dataset.pbody = "true"
        noscript.innerHTML = '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=' + process.env.GOOGLE_TAG_MANAGER_ID_EXTRA + '" height="0" width="0" style="display:none;visibility:hidden" title="gtm"></iframe></noscript>'
        document.body.prepend(noscript)
    }
}