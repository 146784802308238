import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

import { useUiNotification } from '~/composables';
import { useApi } from '~/composables/useApi';
import { trimString } from '~/diptyqueTheme/helpers/cleanFormData';

import sendContactInfoQuery from '../../customQueries/magento/sendContactInformation';
import type { SendContactParsedResponse, UseContactInterface } from './types';

export const useContact = (): UseContactInterface => {
  const { send: sendNotification } = useUiNotification();
  const { mutate } = useApi();
  const { app } = useContext();
  const loading = ref(false);

  const sendContactInformation = async (inputData) => {
    Logger.debug('useContact/sendContactInformation');
    loading.value = true;
    try {
      const { data, errors } = await mutate(sendContactInfoQuery, {
        input: {
          firstname: trimString(inputData['firstname']),
          lastname: trimString(inputData['lastname']),
          email: inputData['email'],
          mobile: inputData['mobile'],
          object: inputData['object'],
          orderId: inputData['orderId'],
          comment: inputData['comment']
        }
      });

      if (errors) {
        if (Array.isArray(errors)) {
          const joinedErrors = errors.map((e) => e.message).join(',');
          throw new Error(joinedErrors);
        }
      } else {
        //@ts-ignore
        const parsedData: SendContactParsedResponse = data?.sendContactInformation && JSON.parse(data.sendContactInformation);
        if (parsedData) {
          if (parsedData.success) {
            sendNotification({
              id: Symbol(''),
              message: app.i18n.t(parsedData.message) as string,
              type: 'success',
              persist: false,
              area: 'top',
              icon: 'check',
              title: app.i18n.t('Contact Us') as string
            });
          } else {
            throw new Error(parsedData.message);
          }
        }
      }
    } catch (err) {
      Logger.error('useContact/sendContactInformation', err);
      console.error(err);
      sendNotification({
        id: Symbol(''),
        message: app.i18n.t(err.message) as string,
        type: 'danger',
        area: 'top',
        persist: false,
        title: app.i18n.t('Something went wrong!') as string
      });
      return false;
    } finally {
      loading.value = false;
    }
    return true;
  };

  return {
    sendContactInformation,
    loading: readonly(loading)
  };
};
