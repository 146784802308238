import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { Plugin } from '@nuxt/types';

import type { UiNotification } from '~/composables/useUiNotification';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) =>
  res?.errors?.some(
    (error) =>
      error?.extensions?.category === 'graphql-authorization' ||
      (error?.extensions?.category === 'graphql-authentication' && error?.path?.[0] === 'customer')
  ) ?? false;

const plugin: Plugin = ({ $pinia, app }) => {
  const customerStore = useCustomerStore($pinia);
  const getCustomerToken = () => app.$vsf.$magento.config.state.getCustomerToken();

  if (getCustomerToken()) {
    customerStore.setIsLoggedIn(true);
    customerStore.setIsTokenExpired(false);
  }

  const isGuest = () => !customerStore.isLoggedIn && !getCustomerToken();
  const isLogged = () => customerStore.isLoggedIn;

  const isTokenAvailable = () => !!getCustomerToken();
  const isTokenExpired = () => customerStore.isLoggedIn && !getCustomerToken();

  const handleTokenExpired = () => {
    customerStore.setIsLoggedIn(false);
    customerStore.setIsTokenExpired(true);

    if (isTokenAvailable()) {
      app.$vsf.$magento.config.state.removeCustomerToken();
    }

    app.$vsf.$magento.config.state.removeCartId();

    app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol(''),
      message: app.i18n.t('Your session has expired. Log in to continue your shopping.') as string,
      type: 'warning',
      icon: null,
      persist: false,
      title: null
    });

    app.$bus.$emit('token-expired');
    app.router.push(app.localePath('/'));
  };

  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>) && (isGuest() || isLogged())) {
      customerStore.setIsTokenExpired(false);

      return res;
    }

    handleTokenExpired();
    return false;
  });

  app.$vsf.$adyen.client.interceptors.request.use((config) => {
    if (isLogged() && isTokenExpired()) {
      handleTokenExpired();
      return Promise.reject(new Error());
    }

    return config;
  });
};

export default plugin;
