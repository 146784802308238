import { computed, reactive, ref, useContext } from '@nuxtjs/composition-api';

import type { UiNotification, UseUiNotificationInterface } from './useUiNotification';

interface Notifications {
  notifications: UiNotification[];
}

const state = reactive<Notifications>({
  notifications: []
});
const isVisible = ref(false);
const maxVisibleNotifications = 3;
const timeToLive = 3000;

/**
 * Allows managing and showing notifications to the user.
 *
 * See the {@link UseUiNotificationInterface} for a list of methods and values available in this composable.
 */
export function useUiNotification(): UseUiNotificationInterface {
  const { app } = useContext();

  const cookieMessage = app.$vsf.$magento.config.state.getMessage<UiNotification>();

  const waitForCondition = (conditionFn: () => boolean): Promise<void> => {
    return new Promise<void>((resolve) => {
      const checkCondition = () => {
        if (conditionFn()) {
          resolve();
        } else {
          requestAnimationFrame(checkCondition);
        }
      };
      checkCondition();
    });
  };

  const send = async (notification: UiNotification, timeToClose = null) => {
    const id = Symbol('id');
    isVisible.value = true;
    let dismissTimeout: ReturnType<typeof setTimeout> | null = null;

    const NOT_FOUND_INDEX = -1;
    const dismiss = async () => {
      isVisible.value = false;

      await waitForCondition(() => !isVisible.value);

      const index = state.notifications.findIndex((n) => n.id === id);

      if (index !== NOT_FOUND_INDEX) {
        state.notifications.splice(index, 1);
        app.$vsf.$magento.config.state.removeMessage();
      }
    };

    const newNotification = {
      ...notification,
      id,
      dismiss,
      hoverEnter: () => {
        if (dismissTimeout) clearTimeout(dismissTimeout);
      },
      hoverLeave: () => {
        dismissTimeout = setTimeout(dismiss, timeToClose ?? timeToLive);
      }
    };

    if (!state.notifications.some((stateNotification) => stateNotification.message === notification.message)) {
      state.notifications.push(newNotification);
    }

    if (state.notifications.length > maxVisibleNotifications) {
      state.notifications.shift();
    }

    if (!notification.persist) {
      dismissTimeout = setTimeout(dismiss, timeToClose ?? timeToLive);
    }
  };

  if (cookieMessage) {
    send(cookieMessage);
    app.$vsf.$magento.config.state.removeMessage();
  }

  return {
    send,
    notifications: computed(() => state.notifications),
    isVisible
  };
}

export default useUiNotification;
export * from './useUiNotification';
