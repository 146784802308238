import { computed, readonly, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import type { ComposableFunctionArgs, UseUrlResolverErrors, UseUrlResolverInterface } from '~/composables';
import { useContentfulPreview, usePreviewMode } from '~/diptyqueTheme/composable';
import { Logger } from '~/helpers/logger';
import type { CategoryTree, RoutableInterface } from '~/modules/GraphQL/types';

/**
 * Allows searching the resolver for current
 * route path (URL).
 *
 * See the {@link UseUrlResolverInterface} for a list of methods and values available in this composable.
 */
export function useUrlResolver(): UseUrlResolverInterface {
  const routeData = sharedRef<CategoryTree | null>(null, 'useUrlResolver-routeData');
  const route = useRoute();
  const {
    error: nuxtError,
    app: {
      localePath,
      i18n,
      $vsf: { $magento }
    }
  } = useContext();

  const { getCategoryLink, categoryURL } = useContentfulPreview();
  const { isPreviewMode } = usePreviewMode();

  const loading = ref(false);
  const error = ref<UseUrlResolverErrors>({
    search: null
  });

  const search = async (params?: ComposableFunctionArgs<{}>): Promise<RoutableInterface | null> => {
    loading.value = true;
    const { path } = route.value;

    let results: RoutableInterface | null = null;
    try {
      let clearUrl = 'l/' + path.replace(localePath('/l'), '').replace(`/${i18n.locale}/`, '').trim();
      if (isPreviewMode.value) {
        await getCategoryLink();
        clearUrl = categoryURL.value;
      }
      Logger.debug('[Magento] Find information based on URL', { clearUrl });
      const { data } = await $magento.api.route(clearUrl, params?.customQuery ?? null);
      results = data?.route ?? null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      routeData.value = results;

      if (!results) {
        Logger.error(['useUrlResolver', 'Not found', { path, clearUrl }]);
        nuxtError({ statusCode: 404 });
        return;
      }

      Logger.debug('[Result]:', { results });
    } catch (err) {
      error.value.search = err;

      Logger.error('useUrlResolver/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    routeData: computed(() => routeData.value),
    path: computed(() => route.value?.path),
    search,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './UseUrlResolver';
export default useUrlResolver;
