import { Logger } from '~/helpers/logger';

export const tryJsonParse = <T>(jsonString: string, context: string): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (err) {
    Logger.error(`${context} - JSON parse error`, err);
    return null;
  }
};
