import { PiniaPluginContext } from 'pinia';
import { Plugin } from '@nuxt/types';
import { ssrRef, set } from '@nuxtjs/composition-api';
import type { StoreConfig } from '~/modules/GraphQL/types';
import { useConfigCache } from '~/composables/useConfig';

const storeConfigPlugin: Plugin = async ({ $pinia, app }) => {
  const storeConfig = ssrRef(null, 'store-config-plugin');

  if (storeConfig.value === null) {
    const configCache = useConfigCache({ $magento: app.$vsf.$magento, locale: app.i18n.localeProperties.code });

    const { data }: { data: { storeConfig?: StoreConfig } } = await configCache.execute();
    storeConfig.value = data?.storeConfig ?? {};
  }

  $pinia.use(({ store }: PiniaPluginContext) => {
    if (store.$id !== 'magentoConfig') {
      return;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!store.$state.hasOwnProperty('storeConfig')) {
      set(store.$state, 'storeConfig', storeConfig);
    } else {
      // eslint-disable-next-line no-param-reassign
      store.$state.storeConfig = storeConfig;
    }
  });
};

export default storeConfigPlugin;
