import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseThemeUiStateInterface } from './useThemeUiState';

const state = reactive<StateInterface>({
  isStoreSwitcherModalOpen: false,
  isMobileStoreSwitcherOpen: false
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useThemeUiState(): UseThemeUiStateInterface {
  const toggleStoreSwitcherModal = () => {
    state.isStoreSwitcherModalOpen = !state.isStoreSwitcherModalOpen;
  };

  const toggleMobileStoreSwitcher = () => {
    state.isMobileStoreSwitcherOpen = !state.isMobileStoreSwitcherOpen;
  };

  const closeStoreSwitcherModal = () => {
    state.isStoreSwitcherModalOpen = false;
  };

  const closeMobileStoreSwitcher = () => {
    state.isMobileStoreSwitcherOpen = false;
  };

  return {
    isStoreSwitcherModalOpen: computed(() => state.isStoreSwitcherModalOpen),
    toggleStoreSwitcherModal,
    closeStoreSwitcherModal,
    isMobileStoreSwitcherOpen: computed(() => state.isMobileStoreSwitcherOpen),
    toggleMobileStoreSwitcher,
    closeMobileStoreSwitcher
  };
}

export default useThemeUiState;
export * from './useThemeUiState';
